.TestimonialTitle{
    color: #2AAE9E;
    text-align: center;
    line-height: 78.02px;
    font-size: 64px;
    font-weight: 700;
    margin-bottom: 25px;
}


.TestimonialContanier{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-basis: 250;
    gap: 15px;
}
.TestimonialCard{
    width: 300px;
    height: 364px;
    background-color: #F5F2ED;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 20px 8px 20px 8px;
}
.TestimonialCardDescription{
    color: #413D45;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
    padding: 15px;

}

@media (max-width:700px){
    .TestimonialTitle{
     font-size: 35px;
    }
    
 }