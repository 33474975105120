.footerFullContanier{
    background-color: #242828;
    padding-top: 25px;
    margin-top: 100px;
    padding-bottom: 25px;
}
.navFooter{
    font-size: 15px;
    font-weight: 700;
    line-height: 18.29px;
    color: #FFFFFF;
    text-decoration: none;
}

@media (max-width:700px){
    .footerMobile{
        flex-direction: column;
        gap: 25px;
    }
}