.pargraphSME{
    font-size: 18px;
}
.flexSme{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.flexSme img{
    max-width: 20%;
}
@media (max-width:700px){
    .pargraphSME{
        font-size: 12px;
    }
    .flexSme{
        flex-direction: column;
    }
    .flexSme img{
        max-width: 80%;
    }
}

.SMECardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
  }
 
  .SMECardContainer div {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    margin-top: 25px;
  }

