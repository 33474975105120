
.HomeCompo
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 150px;
}
.TitleHome{
    font-size: 45px;
    font-weight: 500;
    line-height: 54.86px ;
    color: #242828;
}
.subTitleFirst{
    font-size: 13px;
    line-height: 15.85px;
    font-weight: 700;
    color: #474C4C ;
}
.subTitleDiscription{
    color:#474C4C ;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    width: 80%;
}


.buttonDiv{
    background-color: #65C5B9;
    padding: 20px 18px 20px 18px;
    width: fit-content;
    cursor: pointer;
}


.buttonStyle{
    font-size: 25px;
    font-weight: 700;
    line-height: 30.48px;
    color: white;
}
.imgStyle{
    width: 624px;
}

@media (max-width:1300px) {
    .imgStyle{
        display: none;
    }
}
@media (max-width:700px){
    .TitleHome{
        font-size:35px;
        font-weight: 500;
        line-height: 34.86px ;
        color: #242828;
    }
    .buttonDiv{
        background-color: #65C5B9;
        padding: 10px 8px 10px 8px;
        width: fit-content;
        cursor: pointer;
    }
    .buttonStyle{
        font-size: 15px;
     
    }
} 