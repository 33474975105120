

.aboutTitle{
    color:#474C4C ;
    font-size: 72px;
    font-weight: 700;
    line-height:87.77px ;
    text-align: center;
    margin-bottom: 25px;
}


.aboutCompo{
    background-color:#65C5B921 ;
    padding: 48px 24px 48px 24px;
    border-radius: 24px;
    text-align: center;
}

.flexAbout{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:700px){
   .aboutTitle{
    font-size: 35px;
   }
}
