
.serviceTitle{
    color: #65C5B9;
    text-align: center;
    line-height: 78.02px;
    font-size: 64px;
    font-weight: 700;
    margin-bottom: 25px;
}



.serviceCard{
    width: 300px;
    height: 364px;
    background-color: #474C4C;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px 8px 20px 8px;
}

.serviceCardTitle{
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
}
.serviceCardDescription{
font-size: 15px;
color: #FFFFFF;
line-height: 26px;
font-weight: 400;
text-align: center;
}

.servicesContiner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-basis: 250px;
    gap: 10px;
}


@media (max-width:700px){
    .serviceTitle{
     font-size: 35px;
    }
    
 }