.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #65C5B9;
  color: #fff;
  padding-left: 25px;
  position: relative; /* Add relative positioning */
}

.logo {
  font-size: 1.5rem;
}

.imgLogo {
  width: 79.45px;
  height: 47.69px;
}

.desktop-nav a,
.mobile-nav-links a {
  margin-right: 15px;
  text-decoration: none;
  color: #fff;
  margin: 15px;
}

.mobile-nav {
  display: none; /* Initially hide mobile navigation */
}

.mobile-nav button {
  display: block;
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .imgLogo {
    width: 59.45px;
    height: 37.69px;
  }
  .desktop-nav {
    display: none; /* Hide desktop navigation on smaller screens */
  }

  .mobile-nav {
    display: block; /* Show mobile navigation */
    z-index: 1000; /* Set z-index to ensure it is above other content */
  }

  .mobile-nav-links {
    display: flex;
    flex-direction: column;
    background-color: #333;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 1000; /* Set z-index to ensure it is above other content */
  }

  .mobile-nav-links a {
    padding: 10px;
  }
}

.pointerNav {
  cursor: pointer;
}
