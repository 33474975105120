.ContactHeader{
    background-color: #65C5B9;
    padding: 18px 16px 18px 16px;
    width: fit-content;
}
.contactText{
    color: #FFFFFF;
    line-height: 39.01px;
    font-size: 32px;
    font-weight: 700;
}


.contactInput{
    border: 1px solid #65C5B9;
    background-color: #65C5B92E;
    width: 70%;
    border-radius: 4px;
    height: 60px;
    padding-left: 15px;
    margin-bottom: 25px;
    
}
.contactButton{
    background-color: #65C5B9;
    padding: 10px 18px 10px 18px;
    width: fit-content;
    cursor: pointer;
}
.buttonText{
    color: #FFFFFF;
    line-height: 39.01px;
    font-size: 19px;
    font-weight: 700;
}


@media (max-width:700px){
    .ContactHeader{
        padding: 14px 10px 14px 10px;
    }
    .contactText{
        font-size: 25px;
    }

 }