.WhyImg{
    display: block ;
}


.widthHome2{
    width: 1300px;
  }

  @media (max-width:1300px){
    .widthHome2{
      width: 100%;
    }
  }

  .WhycomeMobile{
    display: none;
  }

  @media (max-width:700px){
    .WhyImg{
        display: none ;
    }
    .WhycomeMobile{
        display: block;
    }
  }