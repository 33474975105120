@media (max-width:700px){
    .bussinesMobile{
     flex-direction: column;
    }
    .bussinesCard{
        background-color: #65C5B9;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 15px;
    }
    .bussinesCardNone{
        background-color: white;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 15px; 
        border:1px solid black  
    }
   
 }