.engamnetTitle{
    color:#474C4C ;
    font-size: 72px;
    font-weight: 700;
    line-height:87.77px ;
    text-align: center;
    margin-bottom: 25px;
}
.EngagementCardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
  }
 
  .EngagementCardContainer div {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    margin-top: 25px;
  }

@media (max-width:700px){
    .engamnetTitle{
     font-size: 25px;
    }
 }