
.fullContanier{
    background-color:#65C5B966 ;
    padding-top: 25px;
    margin-top: 100px;
    padding-bottom: 25px;
}


.workContanier{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.moneyImg{
    width: 458px;
    height:302px ;
    display: block;
    overflow: hidden;
  }



  .ourWorkImgTitle{
    color: #313131;
    line-height: 69.7px;
    font-size: 64px;
    font-weight: 600;
  }


  .sepratorDiv{
    height: 20px;
    width: 390px;
    background-color: #00000091;
    border-radius: 4px;
  }


  .workBigFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }
.firstDivContanier{
    width: 368px;
    height: 497px;
    background-color: #EBF7F6;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 20px 8px 20px 8px;
}
.firstDivTitle{
    color: #000000;
    font-size: 48px;
    font-weight: 600;
    line-height: 52.27px;
}
.firstDivDiscription{
    font-size: 20px;
    color: #000000B8;
    line-height: 21.78px;
    font-weight: 500;
    text-align: center;
}
.SecondDivContanier{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
}


.firstInside{
    width: 390px;
    height: 210px;
    background-color: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 8px 20px 8px;
}


.firstInsideTitle{
    color: #000000;
    font-size: 32px;
    font-weight: 500;
    line-height: 34.85px;
}
.firstDivDiscription{
    font-size: 16px;
    color: #000000B8;
    line-height: 17.42px;
    font-weight: 400;
    text-align: center;
}


.SecondInside{
    width: 390px;
    height: 273px;
    background-color: #EBF7F6;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 8px 20px 8px;
}
.SecondInsideTitle{
    color: #000000;
    font-size: 32px;
    font-weight: 600;
    line-height: 34.85;
}

@media (max-width:1300px)
{
    .workContanier{
        flex-direction: column;
        gap: 15px;
    }
}

@media (max-width:700px){
    .ourWorkImgTitle{
        color: #313131;
        line-height: 39.7px;
        font-size: 44px;
        font-weight: 600;
        margin-left: 15px;
        margin-bottom: 15px;
      }
      .firstDivTitle{
        font-size: 38px;
      }
}