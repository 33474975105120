body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.continer
{
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    margin: auto;
   
}
.font{
  font-family: 'Montserrat', sans-serif;

}


.widthHome{
  width: 1300px;
}



@media (max-width:1300px){
  .widthHome{
    width: 100%;
  }
}


@media (max-width:767px) {
  .mobileView{
     flex-direction: column;
  }
  .mobileViewHome{
    flex-direction: column;
  }
  .widthHome img{
    display: none;
  }
  .workMobile{
    flex-direction: column;
    gap: 15px;
  }
 
  .sirveceMobile{
    flex-direction: column;
  }

}

@media (min-width:991px) 
{
  .navmobile{
    justify-content: end;
  }
}

@media (max-width:950px){
  .widthHome img{
    display: none;
  }
}

